.services {
  width: 100%;
  padding: 50px 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .layout {
    //width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .container {
      //width: 100%;
      max-width: 1170px;
      //padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .header {
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .subText {
          color: #116071;
          font-family: "Palanquin", sans-serif;
          font-size: 30px;
          font-weight: 600;
          line-height: 28px;
          text-transform: uppercase;
          text-align: center;

          @media only screen and (max-width: 768px) {
            font-size: 21px;
          }

          @media only screen and (max-width: 425px) {
            font-size: 15px;
          }
        }

        .title {
          color: #333333;
          font-family: "Palanquin", san-serif;
          text-align: center;
          text-transform: uppercase;
          font-size: 40px;
          font-weight: 600;
          margin: 0 0 15px;
          line-height: 1.5;

          @media only screen and (max-width: 768px) {
            font-size: 30px;
            margin: 0;
          }

          @media only screen and (max-width: 425px) {
            font-size: 25px;
          }
        }
      }

      .reasons {
        display: flex;
        justify-content: center;

        @media only screen and (max-width: 768px) {
          flex-direction: column;
          align-items: center;
        }

        .reasonContainer {
          width: 100%;
          height: 100%;
          // min-height: 557px;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          @media only screen and (max-width: 768px) {
            margin-bottom: 20px;
          }

          .iconContainer {
            width: 100%;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            .icon {
              width: 100px;
              height: 100px;
              margin: 0 0 15px;
              border-radius: 100px;
              //background-color: #116071;
              box-shadow: 0px 3px 7px #8888889d;
              display: flex;
              justify-content: center;
              align-items: center;

              &.white {
                background-color: #ffffff;
                color: #116071;
                transition: transform 0.2s ease-in;

                &:hover {
                  transform: scale(1.1);
                  color: #ffffff;
                  background-color: #116071;
                }
              }

              &.blue {
                background-color: #116071;
                color: #ffffff;
                transition: transform 0.2s ease-in;

                &:hover {
                  transform: scale(1.1);
                  background-color: #ffffff;
                  color: #116071;
                }
              }
            }
          }

          .label {
            max-width: 800px;
            width: 100%;
            color: #116071;
            font-family: "Palanquin", sans-serif;
            font-size: 42px;
            font-weight: normal;
            margin: 0 0 16px;
            text-transform: uppercase;
            text-align: center;
            line-height: 1.1;

            @media only screen and (max-width: 1024px) {
              font-size: 35px;
            }

            @media only screen and (max-width: 615px) {
              font-size: 30px;
            }

            @media only screen and (max-width: 531px) {
              font-size: 23px;
            }

            @media only screen and (max-width: 425px) {
              font-size: 20px;
            }

            @media only screen and (max-width: 365px) {
              font-size: 17px;
            }
          }

          .description {
            max-width: 800px;
            width: 100%;
            margin: 10px 0 0;
            color: #252525;
            font-family: "Palanquin", sans-serif;
            font-size: 16px;
            text-align: center;
            line-height: 1.5;

            @media only screen and (max-width: 768px) {
              //width: 75%;
              font-size: 14px;
            }
          }
        }
      }

      .certification {
        width: 100%;
        padding: 25px 0 0 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 471px) {
          flex-direction: column;
          padding: 0;
        }

        .logoContainer {
          width: 145px;
          height: 145px;
          padding: 0 5px;

          @media only screen and (max-width: 471px) {
            padding: 10px 0;
          }

          .logo {
            width: 100%;
            height: 100%;
          }
        }

        .logoContainer2 {
          width: 120px;
          height: 170px;
          padding: 0 5px;

          @media only screen and (max-width: 425px) {
            padding: 10px 0;
          }

          .logo2 {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}