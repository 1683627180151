.menu {
  width: 95%;
  max-width: 320px;
  height: 100%;
  background-color: #116071;
  position: fixed;
  top: 0;
  left: -350px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  transition: all 0.5s;

  &.active {
    left: 0;
  }

  .head {
    width: calc(100% - 30px);
    padding: 15px;
    margin: 0 0 15px;
    border-bottom: 1px solid #ffffff45;
    display: flex;
    justify-content: space-between;

    .logoContainer {
      width: auto;
      height: 48px;
      //padding: 15px 0;
      display: flex;
      justify-content: flex-start;

      .logoLink {
        .logo {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .IconContainer {
      width: 85%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .accLink {
    color: #ffffff;
    text-decoration: none;
    font-family: "Palanquin", sans-serif;
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .container {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .galleryContainer {
      padding: 0 16px;
      display: flex;

      .galleryMenuLink {
        width: 100%;
        margin: 12px 0 12px 8px;
        color: #ffffff;
        font-size: 16px;
        font-family: "Palanquin";
        text-transform: uppercase;
        text-decoration: none;
        display: flex;
        justify-content: flex-start;
      }
    }

    .contactContainer {
      padding: 0 16px;
      display: flex;

      .contact {
        width: 100%;
        margin: 12px 0 12px 8px;
        color: #ffffff;
        font-size: 16px;
        font-family: "Palanquin";
        text-transform: uppercase;
        text-decoration: none;
        display: flex;
        justify-content: flex-start;
      }
    }

    .socials {
      width: calc(100% - 1px);
      border: 1px solid #ffffff45;
      border-right: none;
      display: flex;
      justify-content: center;

      .icon {
        height: 50px;
        width: 100%;
        border-right: 1px solid #ffffff45;
        text-decoration: none;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  // &.active {
  //   left: 0;
  // }

  // .container {
  //   width: 100%;
  //   height: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   //justify-content: space-between;

  //   .drawer,
  //   .drawer2 {
  //     width: 100%;
  //     .drawerBtn,
  //     .drawerBtn2 {
  //       color: #ffffff;
  //       background-color: #116071;
  //       font-size: 16px;
  //       font-family: "Palanquin", sans-serif;
  //       padding: 12px 15px;
  //       text-transform: uppercase;
  //       display: flex;
  //       justify-content: space-between;
  //       align-items: center;
  //     }

  //     .drawerContent,
  //     .drawerContent2 {
  //       //height: 0px;
  //       //display: none;
  //       padding-left: 30px;
  //       background-color: #252525;
  //       display: flex;
  //       flex-direction: column;
  //       // transition: height 4s ease;

  //       .drawerLink,
  //       .drawerLink2 {
  //         color: #ffffff;

  //         font-size: 16.2px;
  //         font-family: "Palanquin", sans-serif;
  //         padding: 12px 15px;
  //         text-transform: uppercase;
  //         text-decoration: none;
  //         display: none;

  //         &.activeLink,
  //         &.activeLink2 {
  //           display: block;
  //         }
  //       }
  //     }
  //   }

  //   .link {
  //     color: #ffffff;
  //     font-size: 16px;
  //     font-family: "Palanquin", sans-serif;
  //     padding: 12px 15px;
  //     text-transform: uppercase;
  //   }
  // }
  // .socials {
  //   width: calc(100% - 1px);
  //   border: 1px solid #ffffff45;
  //   border-right: none;
  //   display: flex;
  //   justify-content: center;

  //   .icon {
  //     height: 50px;
  //     width: 100%;
  //     border-right: 1px solid #ffffff45;
  //     text-decoration: none;
  //     color: #ffffff;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //   }
  // }
}