.notFound {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .notFoundHeader {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.75);
    @media only screen and (max-width: 1199px) {
      height: 225px;
    }
    @media only screen and (max-width: 768px) {
      height: 140px;
    }
    .title {
      color: #252525;
      text-transform: uppercase;
      font-family: "Palanquin", sans-serif;
      font-size: 65px;
      font-weight: 500;
      line-height: 1.1;
      @media only screen and (max-width: 1199px) {
        font-size: 50px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 35px;
      }
    }
  }
  .layout {
    width: 100%;
    padding: 60px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 768px) {
      padding: 30px 0;
    }
    .container {
      max-width: 1170px;
      width: 100%;
      padding: 0 15px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .content {
        width: 100%;
        font-family: "Palanquin", sans-serif;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media only screen and (max-width: 768px) {
          font-size: 14px;
        }
        .help {
          color: #252525;
        }
        .menu404 {
          line-height: 1.5;
          .link {
            text-decoration: none;
          }
        }
      }
    }
  }
}
