.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .footerTop {
    width: 100%;
    //padding: 30px 0px;
    background-color: #116071;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 802px) {
      width: calc(100% - 30px);
      padding: 15px 15px;
    }
    .container {
      width: 100%;
      max-width: 1170px;
      //padding: 0 15px;
      margin: auto;
      .content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: 802px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .links {
          display: flex;
          justify-content: center;
          align-items: center;
          @media only screen and (max-width: 802px) {
            flex-direction: column;
          }
          .link {
            color: #ffffff;
            font-family: "Palanquin", sans-serif;
            font-size: 16px;
            padding: 7.5px;
            line-height: 2.3;
            text-decoration: none;
            transition: color 0.15s ease-in;
            &:hover {
              color: #252525;
            }
            @media only screen and (max-width: 802px) {
              padding: 0;
            }
          }
        }

        .logo {
          padding: 13px 0px 13px 0;
          display: flex;
          align-items: center;
          .badgerText {
            color: #ffffff;
            font-family: "Palanquin", sans-serif;
            text-transform: uppercase;
            //text-align: center;
            line-height: 1;
            margin-right: 10px;
          }
          .badgerLand {
            height: 70px;

            .badgerLogo {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .footerBottom {
    width: 100%;
    padding: 20px 0px;
    background-color: #252525;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 802px) {
      display: none;
    }
    .container {
      width: 100%;
      max-width: 1170px;
      margin: auto;
      .content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .textContainer {
          width: 33.33%;
          margin-left: 7.5px;
          display: flex;
          justify-content: flex-start;
          .text {
            color: #ffffff;
            font-family: "Palanquin", sans-serif;
            font-size: 14px;
            text-transform: uppercase;
            line-height: 1;
            @media only screen and (max-width: 902px) {
              font-size: 12px;
            }
          }
        }
        .logoContainer {
          width: auto;
          height: 110px;
          .logo {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .icons {
          width: 33.33%;
          display: flex;
          justify-content: flex-end;
          .iconContainer {
            padding: 0 7.5px 0 60px;
            .icon {
              color: #ffffff;
              transition: color 0.15s ease-in;
              cursor: pointer;

              &:hover {
                color: #116071;
              }
            }
          }
        }
      }
    }
  }

  .footerBottomMbl {
    width: 100%;
    padding: 20px 0px;
    background-color: #252525;
    display: none;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 802px) {
      display: flex;
      padding: 15px 0;
    }
    .container {
      width: 100%;
      max-width: 1170px;
      margin: auto;
      .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .textContainer {
          width: 100%;
          margin: 0;
          text-align: center;
          display: flex;
          justify-content: center;
          .text {
            margin: 0;
            padding: 15px 0;
            color: #ffffff;
            font-family: "Palanquin", sans-serif;
            font-size: 12px;
            text-transform: uppercase;
            line-height: 1;
          }
        }
        .logoContainer {
          width: auto;
          height: 110px;
          padding: 15px 0;
          .logo {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .icons {
          width: 100%;
          padding: 15px 0;
          display: flex;
          justify-content: center;
          .iconContainer {
            padding: 0 30px;
            .icon {
              color: #ffffff;
              transition: color 0.15s ease-in;
              cursor: pointer;

              &:hover {
                color: #116071;
              }
            }
          }
        }
      }
    }
  }
}
