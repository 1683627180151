.home {
  width: 100%;
  height: 740px;
  background-position: -31px 27%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.75);

  @media only screen and (max-width: 768px) {
    height: 400px;
    background-position: -20px 15%;
  }

  @media only screen and (max-width: 425px) {
    height: 440px;
    background-position: -22px 18%;
  }

  .layout {
    width: 100%;
    max-width: 1200px;
    height: 42%;
    padding: 0 15px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1043px) {
      padding: 0;
    }

    .content {
      width: 100%;
      //height: calc(100% - 60px);
      height: 210px;
      padding: 30px 10px;
      background-color: #00000080;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      //animation: 1s ease-out 0s 1 slideInFromTop;

      // @media only screen and (orientation: landscape) {
      //   height: 100%;
      //   justify-content: center;
      //   align-items: center;
      // }

      @media only screen and (max-width: 768px) {
        height: 160px;
      }

      @media only screen and (max-width: 425px) {
        height: 200px;
      }

      @keyframes slideInFromTop {
        0% {
          transform: translateY(-200%);
        }

        100% {
          transform: translateY(0);
        }
      }

      .text {
        width: 100%;
        height: 100%;
        margin: 0;
        line-height: 1.1;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media only screen and (max-width: 833px) {
          margin-top: 15px;
        }

        @media only screen and (max-width: 768px) {
          margin: 0;
        }

        h1 {
          height: auto;
          margin: 0;
          font-family: "Palanquin", sans-serif;
          font-size: 60px;
          font-weight: normal;
          color: #ffffff;
          text-align: center;

          @media only screen and (max-width: 1134px) {
            font-size: 55px;
          }

          @media only screen and (max-width: 1014px) {
            font-size: 50px;
          }

          @media only screen and (max-width: 923px) {
            font-size: 45px;
          }

          @media only screen and (max-width: 833px) {
            font-size: 40px;
          }

          @media only screen and (max-width: 742px) {
            font-size: 35px;
          }

          @media only screen and (max-width: 768px) {
            font-size: 30px;
          }

          @media only screen and (max-width: 652px) {
            font-size: 30px;
          }

          @media only screen and (max-width: 562px) {
            font-size: 25px;
          }

          @media only screen and (max-width: 471px) {
            font-size: 22px;
          }

          @media only screen and (max-width: 425px) {
            font-size: 30px;
          }
        }

        .subText {
          height: 100%;
          font-family: "Palanquin", sans-serif;
          font-size: 30px;
          color: #ffffff;
          text-align: center;

          @media only screen and (max-width: 833px) {
            font-size: 25px;
          }

          @media only screen and (max-width: 768px) {
            font-size: 20px;
          }

          @media only screen and (max-width: 652px) {
            font-size: 20px;
          }

          @media only screen and (max-width: 562px) {
            font-size: 17px;
          }

          @media only screen and (max-width: 425px) {
            font-size: 17px;
          }

          @media only screen and (max-width: 367px) {
            font-size: 17px;
          }
        }
      }

      .btnContainer {
        display: flex;
        justify-content: center;

        .btn {
          font-family: "Palanquin", sans-serif;
          font-size: 16px;
          padding: 15px 40px;
          color: #ffffff;
          background-color: #116071;
          text-decoration: none;
          transition: background-color 0.15s ease-in;

          @media only screen and (max-width: 915px) and (orientation: landscape) {
            padding: 8px 20px;
            margin-top: 10px;
          }

          @media only screen and (max-width: 299px) {
            padding: 8px 20px;
            margin-top: 10px;
          }

          &:hover {
            background-color: #0f5261;
          }
        }
      }
    }
  }
}