.contact {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;

  .layout {
    width: 100%;
    max-width: 1170px;
    margin: auto;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1199px) {
      max-width: 940px;
    }

    .content {
      width: 100%;
      padding: 45px 15px;
      display: flex;
      justify-content: center;

      @media only screen and (max-width: 802px) {
        max-width: 720px;
        flex-direction: column;
      }

      .infoContainer {
        width: calc(40% - 15px);
        padding: 30px;
        margin-right: 15px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);

        @media only screen and (max-width: 802px) {
          width: calc(100% - 60px);
          margin: 0;
          padding-bottom: 0;
          box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
        }

        @media only screen and (max-width: 768px) {
          width: calc(100% - 30px);
          padding: 30px 15px 0;
        }

        .infoContent {
          color: #252525;
          font-family: "Palanquin", sans-serif;

          .name {
            color: #116071;
            font-family: "Palanquin", sans-serif;
            font-size: 42px;
            margin: 0 0 16px;
            line-height: 1.1;
            font-weight: normal;
            text-transform: uppercase;

            @media only screen and (max-width: 1199px) {
              font-size: 34px;
            }

            @media only screen and (max-width: 963px) {
              font-size: 30px;
            }

            @media only screen and (max-width: 873px) {
              font-size: 22.8px;
            }
          }

          .address {
            color: #252525;
            font-family: "Palanquin", sans-serif;
            font-size: 16px;
            margin: 0 0 15px;
            line-height: 1.5;

            .label {}

            .details {
              margin: 0;
            }
          }

          .phone {
            margin: 0 0 15px;
            line-height: 1.5;

            .label {}

            .details {
              color: #116071;
              text-decoration: none;
            }
          }

          .hours {
            margin: 0 0 15px;
            line-height: 1.5;

            .label {}
          }
        }
      }

      .formContainer {
        width: 60%;
        padding: 0 15px 5px 30px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);

        @media only screen and (max-width: 802px) {
          width: calc(100% - 60px);
          //margin: 10px 0 0;
          padding: 30px 30px 5px;
          box-shadow: 0px 20px 10px rgba(0, 0, 0, 0.3);
        }

        @media only screen and (max-width: 768px) {
          width: calc(100% - 30px);
          padding: 0 15px 5px;
        }

        .formContent {
          .form {
            label {
              width: 100%;
              color: #252525;
              font-family: "Palanquin", sans-serif;
              font-size: 16px;
              font-weight: 700;
              margin: 0 0 4px;
              display: flex;
              flex-direction: column;

              &.left {
                padding: 8px 12px 8px 0;

                @media only screen and (max-width: 768px) {
                  padding: 7px 0;
                  margin: 0;
                }
              }

              &.right {
                padding: 8px 0 8px 12px;

                @media only screen and (max-width: 768px) {
                  padding: 7px 0;
                  margin: 0;
                }
              }

              &.bottom {
                padding: 8px 0;

                @media only screen and (max-width: 768px) {
                  padding: 7px 0;
                  margin: 0;
                }

                span {
                  border-bottom: 1px solid #707070;
                }
              }
            }

            input {
              font-size: 15px;
              padding: 8px;
              border: none;
              border-bottom: 1px solid #707070;
              outline: none;
            }

            textarea {
              width: calc(100% - 16px);
              height: 133px;
              font-family: "Palanquin", sans-serif;
              font-size: 16px;
              padding: 8px;
              border: none;
              border-bottom: 1px solid #707070;
              outline: none;
              resize: none;
            }

            .row {
              width: 100%;
              display: flex;

              @media only screen and (max-width: 768px) {
                flex-direction: column;
              }
            }

            .btnContainer {
              width: 151.78px;
              height: auto;
              position: relative;

              .submitBtn {
                color: #ffffff;
                background-color: #116071;
                font-family: "Palanquin", sans-serif;
                font-size: 18px;
                border: none;
                padding: 11px 45px;
                text-transform: uppercase;
                cursor: pointer;
                transition: background-color 0.15s ease-in;

                &:hover {
                  background-color: #0f5261;
                }
              }

              .loadingClass {
                opacity: 0;
              }

              .boxContainer {
                height: 55px;
                width: 151.78px;
                background-color: #116071;
                position: absolute;
                bottom: 0;
                display: none;
              }

              .boxVis {
                display: block;
              }

              .loading {
                width: 40px;
                position: absolute;
                top: 0;
                bottom: 0;
                left: calc(50% - 20px);
                right: 0;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}