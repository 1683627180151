.navbar {
  width: 100%;
  background-color: #252525;
  display: flex;
  justify-content: center;

  .container {
    width: 100%;
    max-width: 1170px;
    height: 150px;
    padding: 0 15px;
    margin: auto;
    display: flex;

    @media only screen and (max-width: 768px) {
      height: 96px;
      padding: 0;
    }

    .hamburgerContainer {
      display: none;

      @media only screen and (max-width: 768px) {
        max-width: 25%;
        width: 100%;
        padding: 15px 0 15px 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      @media only screen and (max-width: 768px) {
        max-width: 20%;
      }

      .hamburger {
        width: auto;
        height: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        span {
          width: 32px;
          height: 4px;
          border-radius: 4px;
          background-color: #ffffff;
          display: flex;
        }
      }
    }

    .logoContainer {
      max-width: 25%;
      width: 100%;
      height: 120px;
      padding: 15px 0;
      display: flex;
      justify-content: flex-start;

      @media only screen and (max-width: 768px) {
        height: 80px;
        max-width: 100%;
        width: 100%;
        padding: 8px 7.5px;
        justify-content: center;
      }

      @media only screen and (max-width: 365px) {}

      .logo {
        width: 100%;
        height: 100%;
        object-fit: contain;

        @media only screen and (max-width: 768px) {
          height: 80px;
          // width: 80px;
        }
      }
    }

    .iconContainer {
      width: 0;
      padding: 15px 15px 15px 0;
      //padding: 0 7.5px 0 60px;
      display: none;

      @media only screen and (max-width: 768px) {
        max-width: 25%;
        width: 100%;
        //height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      @media only screen and (max-width: 768px) {
        max-width: 20%;
      }

      .icon {
        height: 100%;
        color: #ffffff;
        transition: color 0.15s ease-in;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        align-self: center;

        .phone {
          font-size: 32px;
          text-decoration: none;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          //display: none;
        }
      }
    }

    .linkContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .content {
        width: auto;
        height: 32%;
        //padding: 0 12px;
        //margin: 0 16px;
        display: flex;
        justify-content: center;
        // align-items: center;
      }

      .galleryContainer {
        //height: 32%;
        padding: 0 10px;
        cursor: pointer;
        scroll-behavior: smooth;

        .galleryLink {
          //padding: 8px 0;
          color: #ffffff;
          font-size: 16px;
          font-family: "Palanquin";
          text-transform: uppercase;
          transition: color 0.2s ease-in;
          text-decoration: none;

          &:hover {
            color: #116071;
          }
        }
      }

      .contactContainer {
        padding-left: 10px;
        cursor: pointer;

        .contact {
          //padding: 8px 0;
          color: #ffffff;
          font-size: 16px;
          font-family: "Palanquin";
          text-transform: uppercase;
          transition: color 0.2s ease-in;
          text-decoration: none;

          &:hover {
            color: #116071;
          }
        }
      }

      .accLink {
        color: #ffffff;
        text-decoration: none;
        font-family: "Palanquin", sans-serif;
        transition: color 0.2s ease-in;
        font-size: 14px;

        &:hover {
          color: #116071;
        }
      }

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}