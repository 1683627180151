.fireplaceServices {
  .fireplaceServicesHeader {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.75);
    @media only screen and (max-width: 1199px) {
      height: 225px;
    }
    @media only screen and (max-width: 768px) {
      height: 140px;
    }
    .title {
      color: #252525;
      text-transform: uppercase;
      font-family: "Palanquin", sans-serif;
      font-size: 65px;
      font-weight: 500;
      line-height: 1.1;
      @media only screen and (max-width: 1199px) {
        font-size: 50px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 35px;
      }
    }
  }

  .container {
    //width: 100%;
    padding: 75px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 768px) {
      padding: 45px 0 40px;
    }

    .layout {
      max-width: 1170px;
      width: 100%;
      padding: 0 15px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .service {
          //width: 100%;
          height: auto;
          padding: 0 15px;
          margin: 0 0 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          transition: all ease-in-out 0.2s;
          &:hover {
            transform: scale(0.97);
          }
          .imgContainer {
            width: 255px;
            height: 255px;
            display: flex;
            @media only screen and (max-width: 964px) {
              width: 295px;
              height: 295px;
            }
            @media only screen and (max-width: 768px) {
              width: 410px;
              height: 410px;
            }
            @media only screen and (max-width: 475px) {
              width: 300px;
              height: 300px;
            }
            .img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .text {
            font-family: "Palanquin", sans-serif;
            font-size: 18px;
            text-transform: uppercase;
            color: #116071;
            transition: all ease-in-out 0.2s;
            &:hover {
              color: #252525;
            }
          }
        }
      }
    }
  }
  .call {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 100%;
    background-position: 0 68%;
    background-repeat: no-repeat;
    .callLayout {
      max-width: 1170px;
      width: 100%;
      padding: 0 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      .callContent {
        margin: auto;
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .callText {
          width: 100%;
          font-family: "Palanquin", sans-serif;
          font-size: 42px;
          color: #ffffff;
          margin: 0 0 16px;
          padding: 0 10px;
          text-transform: uppercase;
          font-weight: normal;
          background-color: #00000080;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          line-height: 1.1;

          @media only screen and (max-width: 964px) {
            font-size: 35px;
          }
          @media only screen and (max-width: 768px) {
            font-size: 30px;
          }
          @media only screen and (max-width: 575px) {
            font-size: 25px;
          }
          @media only screen and (max-width: 475px) {
            font-size: 20px;
          }
        }
        .btnContainer {
          width: 100%;
          margin: 0 0 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          .btn {
            font-family: "Palanquin", sans-serif;
            color: #ffffff;
            background-color: #116071;
            font-size: 16px;
            padding: 15px 40px;
            text-transform: uppercase;
            text-decoration: none;
          }
        }
      }
    }
  }
}
