.map {
  width: 100%;
  //height: 600px;
  //padding: 35px 0 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .layout {
    //    max-width: 1110px;
    width: 100%;
    margin: auto;
    //padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .container {
      //max-width: 1100px;
      width: 100%;
      //padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .header {
        //margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .subText {
          color: #033167;
          font-family: "Roboto Slab", serif;
          font-size: 16px;
          font-weight: 600;
          line-height: 28px;
          text-transform: uppercase;
          text-align: center;
        }
        .title {
          color: #333333;
          font-family: "Roboto", serif;
          font-size: 30px;
          font-weight: 600;
          margin: 0 0 15px;
        }
      }

      iframe {
        border: none;
        width: 100%;
        height: 600px;

        @media only screen and (max-width: 802px) {
          height: 425px;
        }
      }

      .cityContainer {
        width: 100%;
        display: flex;
        .cities {
          width: 100%;
          padding: 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          .city {
            width: auto;
            margin: 0 8px;
            list-style: none;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon {
              color: #033167;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .cityName {
              color: #54595f;
              font-family: "Roboto", serif;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
