.thankYou {
  .thankYouHeader {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.75);
    @media only screen and (max-width: 1199px) {
      height: 225px;
    }
    @media only screen and (max-width: 768px) {
      height: 140px;
    }
    .title {
      color: #252525;
      text-transform: uppercase;
      font-family: "Palanquin", sans-serif;
      font-size: 65px;
      font-weight: 500;
      line-height: 1.1;
      @media only screen and (max-width: 1199px) {
        font-size: 50px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 35px;
      }
    }
  }
  .layout {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
      max-width: 1170px;
      width: 100%;
      padding: 0 15px;
      margin: auto;
      .content {
        max-width: 800px;
        width: 100%;
        padding: 30px 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        .thankYouText {
          color: #116071;
          font-family: "Palanquin", sans-serif;
          font-size: 30px;
          font-weight: normal;
          margin: 0 0 16px;
          text-transform: uppercase;
          text-align: center;
          line-height: 1.1;
          @media only screen and (max-width: 1200px) {
            font-size: 22px;
          }
          @media only screen and (max-width: 768px) {
            font-size: 18px;
          }
        }
      }
    }
  }
}
