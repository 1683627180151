.pageCloser {
  width: 100%;
  height: 280px;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100%;
  background-position: 0 70%;
  background-repeat: no-repeat;
  .callLayout {
    max-width: 1170px;
    width: 100%;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    .callContent {
      margin: auto;
      padding: 30px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .callText {
        width: 100%;
        font-family: "Palanquin", sans-serif;
        font-size: 42px;
        color: #ffffff;
        margin: 0 0 16px;
        padding: 0 10px;
        text-transform: uppercase;
        font-weight: normal;
        background-color: #00000080;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: 1.1;

        @media only screen and (max-width: 964px) {
          font-size: 35px;
        }
        @media only screen and (max-width: 768px) {
          font-size: 30px;
        }
        @media only screen and (max-width: 575px) {
          font-size: 25px;
        }
        @media only screen and (max-width: 475px) {
          font-size: 20px;
        }
      }
      .btnContainer {
        width: 100%;
        margin: 0 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
          font-family: "Palanquin", sans-serif;
          color: #ffffff;
          background-color: #116071;
          font-size: 16px;
          padding: 15px 40px;
          text-transform: uppercase;
          text-decoration: none;
          transition: background-color 0.15s ease-in;
          &:hover {
            background-color: #0f5261;
          }
        }
      }
    }
  }
}
