.about {
  width: 100%;
  padding: 20px 0;
  background-size: auto;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 65px -20px rgba(0, 0, 0, 0.75) inset;
  .layout {
    width: 100%;
    max-width: 1170px;
    padding: 0 15px;
    margin: auto;
    display: flex;
    justify-content: center;
    .content {
      width: 100%;
      max-width: 800px;
      padding: 30px 0;
      margin: auto;
      //background-color: #fffefe8c;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .textContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title {
          //color: #ffffff;
          color: #116071;
          font-family: "Palanquin", sans-serif;
          font-size: 42px;
          font-weight: normal;
          margin: 0 0 16px;
          text-transform: uppercase;
          text-align: center;
          line-height: 1.1;
          @media only screen and (max-width: 1024px) {
            font-size: 35px;
          }
          @media only screen and (max-width: 615px) {
            font-size: 30px;
          }
          @media only screen and (max-width: 531px) {
            font-size: 23px;
          }
          @media only screen and (max-width: 425px) {
            font-size: 20px;
          }
          @media only screen and (max-width: 365px) {
            font-size: 17px;
          }
        }
        .text {
          color: #252525;
          //color: #ffffff;
          font-family: "Palanquin", sans-serif;
          font-size: 16px;
          margin: 0 0 15px;
          text-align: center;
          line-height: 1.5;
          @media only screen and (max-width: 768px) {
            font-size: 14px;
          }
        }
      }
      .btnContainer {
        width: 100%;
        height: auto;
        margin: 0 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
          color: #ffffff;
          font-family: "Palanquin", sans-serif;
          font-size: 16px;
          background-color: #116071;
          text-decoration: none;
          text-transform: uppercase;
          padding: 15px 40px;
          transition: background-color 0.15s ease-in;
          display: flex;

          &:hover {
            background-color: #0f5261;
          }
        }
      }
    }
  }
}
