.contactHeader {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.75);
  @media only screen and (max-width: 1199px) {
    height: 225px;
  }
  @media only screen and (max-width: 768px) {
    height: 140px;
  }
  .contact {
    color: #252525;
    text-transform: uppercase;
    font-family: "Palanquin", sans-serif;
    font-size: 65px;
    font-weight: 500;
    @media only screen and (max-width: 1199px) {
      font-size: 50px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 35px;
    }
  }
}
