.cItemHeader {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.75);
  @media only screen and (max-width: 1199px) {
    height: 225px;
  }
  @media only screen and (max-width: 768px) {
    height: 140px;
  }
  .title {
    color: #252525;
    text-transform: uppercase;
    font-family: "Palanquin", sans-serif;
    font-size: 65px;
    font-weight: 500;
    line-height: 1.1;
    @media only screen and (max-width: 1199px) {
      font-size: 50px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 35px;
    }
  }
}
.whiteSpace {
  height: 96.8px;
  @media only screen and (max-width: 969px) {
    height: 50px;
  }
}
.topContainer {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .topLayout {
    max-width: 1170px;
    width: 100%;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    .topContent {
      max-width: 800px;
      padding: 30px 0;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .topTitle {
        font-family: "Palanquin", sans-serif;
        font-size: 54px;
        margin: 0 0 16px;
        font-weight: normal;
        color: #116071;
        text-transform: uppercase;
        line-height: 1.1;
        text-align: center;
        @media only screen and (max-width: 1024px) {
          font-size: 35px;
        }
        @media only screen and (max-width: 615px) {
          font-size: 30px;
        }
        @media only screen and (max-width: 531px) {
          font-size: 23px;
        }
        @media only screen and (max-width: 425px) {
          font-size: 20px;
        }
      }
      .topText {
        font-family: "Palanquin", sans-serif;
        font-size: 16px;
        color: #252525;
        margin: 0 0 15px;
        line-height: 1.5;
        text-align: center;
        @media only screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
      .btnContainer {
        width: 100%;
        margin: 0 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
          font-family: "Palanquin", sans-serif;
          color: #ffffff;
          font-size: 16px;
          padding: 15px 40px;
          text-transform: uppercase;
          background-color: #116071;
          text-decoration: none;
          transition: background-color 0.15s ease-in;
          &:hover {
            background-color: #0f5261;
          }
        }
      }
    }
  }
}
.midContainer {
  background-size: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .midLayout {
    max-width: 1170px;
    width: 100%;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 969px) {
      padding: 15px 15px 0;
    }
    .midContent {
      display: flex;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: 969px) {
        flex-direction: column;
      }
      .imgContainer {
        width: 100%;
        max-width: 585px;
        height: 585px;
        @media only screen and (max-width: 1199px) {
          width: 100%;
          max-width: 470px;
          height: 470px;
        }
        @media only screen and (max-width: 969px) {
          width: auto;
          max-width: 700px;
          height: auto;
          margin: 0 0 30px;
        }
        @media only screen and (max-width: 729px) {
          margin: 0 0 10px;
        }
        .img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .midTextContent {
        max-width: 465px;
        width: 100%;
        height: auto;
        padding: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        @media only screen and (max-width: 1199px) {
          max-width: 350px;
        }
        @media only screen and (max-width: 969px) {
          width: 100%;
          max-width: 100%;
          text-align: center;
          padding: 15px;
          align-items: center;
        }
        .midTitle {
          font-family: "Palanquin", sans-serif;
          font-size: 42px;
          color: #116071;
          text-transform: uppercase;
          margin: 0 0 16px;
          line-height: 1.1;
          font-weight: normal;
          @media only screen and (max-width: 969px) {
            font-size: 32px;
          }
          @media only screen and (max-width: 729px) {
            font-size: 22px;
          }
        }
        .midText {
          color: #252525;
          font-family: "Palanquin", sans-serif;
          font-size: 16px;
          margin: 0 0 15px;
          line-height: 1.5;
          @media only screen and (max-width: 729px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
.btmContainer {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .btmLayout {
    max-width: 1170px;
    width: 100%;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    .btmContent {
      max-width: 800px;
      padding: 30px 0;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .btmTitle {
        font-family: "Palanquin", sans-serif;
        font-size: 42px;
        color: #116071;
        text-transform: uppercase;
        margin: 0 0 16px;
        line-height: 1.1;
        font-weight: normal;
        text-align: center;
        @media only screen and (max-width: 969px) {
          font-size: 32px;
        }
        @media only screen and (max-width: 729px) {
          font-size: 22px;
        }
      }
      .btmText {
        color: #252525;
        font-family: "Palanquin", sans-serif;
        font-size: 16px;
        margin: 0 0 15px;
        line-height: 1.5;
        text-align: center;
        @media only screen and (max-width: 729px) {
          font-size: 14px;
        }
      }
    }
  }
}
